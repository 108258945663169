import React, { useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getMultipliedArray, numberWithCommas } from '../helper/numberHelper';
import { QuestionMark } from '../assests/svg';
import { DropdownWithCount } from '../components/dropDownField';
import { ArrayOfQuantity } from '../constant';
import { PremiseAdditionalInfoModal } from './modals';

const Checkout = () => {
  const location = useLocation();
  const history = useHistory();
  const data = location?.state;
  const planDetails = data?.state?.data;
  const [premiseQuantity, setPremiseQuantity] = useState();
  const [isPremiseInfo, setIsPrwemiseInfo] = useState(false);
  const [actualPremiseCount, setActualPremiseCount] = useState();

  const handleBack = () => history.push('/');

  const handleToMakePaymentPage = async () => {
    history.push('/makePayment', {
      state: { ...planDetails, premiseQuantity, actualPremiseCount },
    });
  };

  const handleResetFormValues = () => {
    setPremiseQuantity(planDetails?.features?.premise?.limit);
    setActualPremiseCount(null);
  };

  const handlePremiseQuantity = (val) => {
    if (val) {
      setActualPremiseCount(val?.actualCount);
      setPremiseQuantity(val.value);
    }
  };

  const handlePremiseInfoModal = useCallback(() => {
    setIsPrwemiseInfo(true);
  }, []);
  const handlePremiseInfoModalClosed = useCallback(() => {
    setIsPrwemiseInfo(false);
  }, []);

  return (
    <div className="py-[30px]">
      <div className="w-[80%] mx-auto">
        <h1 className="text-[#7aeaa7] text-2xl font-semibold tracking-wide uppercase mb-[30px]">
          SUBSCRIPTION
        </h1>
        <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
          <div className="bg-[#171a3b] border border-[#494c6a] rounded px-6 h-fit text-center p-8 grid gap-3">
            <h1 className="text-2xl font-semibold tracking-wide text-white uppercase">
              {planDetails?.name}
            </h1>
            <div className="text-[#f9fffd] text-center text-6xl font-bold mb-1 ">
              <span className="text-2xl ">{planDetails?.baseCost > 0 && '$'}</span>
              {numberWithCommas(
                actualPremiseCount
                  ? actualPremiseCount * planDetails?.baseCost
                  : planDetails?.baseCost
              )}
            </div>
            <p className="text-[#f9fffd] text-center text-sm uppercase">
              Total cost per {planDetails?.billingPeriod}{' '}
              <span className="text-[10px]">[INC.GST]</span>
            </p>
          </div>

          <div className="bg-[#171a3b] border border-[#494c6a] rounded px-6 h-fit relative">
            <p
              onClick={handlePremiseInfoModal}
              className="text-xl font-medium text-[#7aeaa7] pt-[20px] uppercase flex gap-2 items-center cursor-pointer"
            >
              Select amount of premises <QuestionMark />
            </p>

            <div className="py-4">
              <DropdownWithCount
                options={getMultipliedArray(ArrayOfQuantity, planDetails?.features?.premise?.limit)}
                onOptionSelect={handlePremiseQuantity}
                selectedOption={premiseQuantity ?? planDetails?.features?.premise?.limit}
                label={'PREMISES'}
                min={planDetails?.features?.premise?.limit}
              />
              <div className="flex justify-between text-[#ffffff] py-[10px] uppercase">
                <span className="text-[#A3A5B8]">Users</span>
                <span>
                  {premiseQuantity
                    ? planDetails?.features?.user?.limit * premiseQuantity
                    : planDetails?.features?.user?.limit}
                </span>
              </div>
              <div className="flex justify-between text-[#ffffff] py-[10px] uppercase">
                <span className="text-[#A3A5B8]">Timesheets</span>
                <span>
                  {premiseQuantity
                    ? planDetails?.features?.timesheet?.limit * premiseQuantity
                    : planDetails?.features?.timesheet?.limit}
                </span>
              </div>
              <div className="flex justify-between text-[#ffffff] py-[10px] uppercase">
                <span className="text-[#A3A5B8]">eCVD's</span>
                <span>
                  {premiseQuantity
                    ? planDetails?.features?.edec?.limit * premiseQuantity
                    : planDetails?.features?.edec?.limit}
                </span>
              </div>
              <div className="flex justify-between text-[#ffffff] py-[10px] uppercase">
                <span className="text-[#A3A5B8]">Geofences</span>
                <span>UNLIMITED</span>
              </div>
              <div className="flex justify-between text-[#ffffff] py-[10px] uppercase">
                <span className="text-[#A3A5B8]">Mobile & Desktop Access</span>
                <span>INCLUDED</span>
              </div>
              <div className="flex justify-between text-[#ffffff] py-[10px] uppercase">
                <span className="text-[#A3A5B8]">Broadcast Capability</span>
                <span>INCLUDED</span>
              </div>
              <div className="flex justify-between text-[#ffffff] py-[10px] uppercase">
                <span className="text-[#A3A5B8]">Links</span>
                <span>INCLUDED</span>
              </div>
              <div className="flex justify-between text-[#ffffff] py-[10px] uppercase">
                <span className="text-[#A3A5B8]">Links Support & Logbook</span>
                <span>INCLUDED</span>
              </div>
              <div className="flex flex-col items-center pt-4 pb-4">
                <button
                  className="mt-4 w-fit bg-[#7aeaa7] text-[#171a3b] py-3 px-14 rounded-full text-lg font-bold uppercase"
                  onClick={handleResetFormValues}
                >
                  Reset value
                </button>
                <button
                  className="mt-4 w-fit bg-[#7aeaa7] text-[#171a3b] py-3 px-14 rounded-full text-lg font-bold uppercase"
                  onClick={handleToMakePaymentPage}
                >
                  Check out
                </button>
              </div>
            </div>
            <p
              className="text-[#A3A5B8] underline underline-offset-2 cursor-pointer text-sm absolute bottom-[-50px] left-1/2 transform -translate-x-1/2"
              onClick={handleBack}
            >
              BACK TO PRODUCT
            </p>
          </div>
        </div>
        <div className="mt-16 pb-4 text-xs text-[#767789] grid grid-cols-2">
          <div>
            <p>
              PREMISE is defined as the Primary Property with an adjoining property which is using
              the same Property Identification Code (PIC) in accordance with the regulations
              controlled by the State Government of Victoria.
            </p>
            <p>UNLIMITED Geofences per PREMISE as defined above.</p>
          </div>
        </div>
      </div>
      {isPremiseInfo && (
        <PremiseAdditionalInfoModal isOpen={isPremiseInfo} onClose={handlePremiseInfoModalClosed} />
      )}
    </div>
  );
};

export default Checkout;
