/* eslint-disable no-throw-literal */
import client from '../axios';
import services from '../services';

export class Session {
  constructor(token, expiresIn) {
    this.token = token;
    this.expiry = new Date().getTime() + expiresIn * 1000;
  }
}

export const login = async (username, password) => {
  const response = await client.post('/auth/login', {
    email: username,
    password: password,
  });

  if (response.status === 200) {
    const data = response.data;

    if (data.type === 'AUTHENTICATION_RESULT') {
      const session = new Session(data.accessToken, data.expiresIn);
      services.setSession(session);
      return session;
    } else {
      // MFA ----
      throw 'Not Implemented yet';
    }
  }

  throw 'Login failed';
};

export const registration = async (payload) => {
  const response = await client.post('/auth/register', payload);

  if (response.status === 200) {
    const data = response.data;
    return data;
  }

  throw 'registration failed';
};

export const verification = async (email, verification_code) => {
  const response = await client.post('/auth/verify', {
    username: email.trim(),
    verificationCode: verification_code.trim(),
  });

  if (response.status === 200) {
    const data = response.data;
    return data;
  }

  throw 'verification failed';
};

export const resendVerificationCode = async (email) => {
  const response = await client.post('/auth/resendOTP', {
    email: email.trim(),
  });

  if (response.status === 200) {
    const data = response.data;
    return data;
  }

  throw 'verification failed';
};
