import React from 'react';

const PaymentSuccessful = () => {
  const handleToWebiste = () => {
    window.open('https://www.bioplus.live', '_self');
  };

  return (
    <div className="m-[80px]">
      <svg viewBox="0 0 24 24" className="w-16 h-16 mx-auto my-6 text-green-600">
        <path
          fill="currentColor"
          d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
        ></path>
      </svg>
      <div className="text-center">
        <h3 className="text-2xl font-medium text-[#ffffff] dark:text-white cls">Payment Done!</h3>
        <p className="my-2 text-[#ffffff] cls">
          Thank you for completing your secure online payment.
        </p>
        <p className="text-[#ffffff] cls">Have a great day!</p>
        <div className="grid justify-center py-10 text-center">
          <button
            className="mt-10 px-14 mb-2 cls bg-[#7aeaa7] text-[#171a3b] py-3  rounded-full text-lg font-bold uppercase"
            onClick={handleToWebiste}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
