import React, { useState, useEffect, useRef } from 'react';

const DropdownWithCount = ({ options, selectedOption, onOptionSelect, label, min }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Add event listener to close dropdown when clicking outside
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    if (min && parseInt(option.label) <= min) {
      return;
    }
    onOptionSelect(option);
    setIsOpen(false);
  };

  const dropdownButtonClasses = `inline-flex justify-center w-full px-4 text-sm font-medium  
    bg-[#171a3b] rounded-sm border-b border-[#494C6A] text-white`;

  return (
    <div className="relative inline-block w-full text-left" ref={dropdownRef}>
      <div>
        <button
          onClick={toggleDropdown}
          type="button"
          className={`${dropdownButtonClasses} flex justify-between py-5`}
          id="options-menu"
          aria-expanded="true"
          aria-haspopup="true"
        >
          <span className="uppercase">{label}</span>
          <div className="flex gap-1">
            <span className="uppercase">{selectedOption}</span>
            <svg
              className="w-5 h-5 ml-2 -mr-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </button>
      </div>

      {isOpen && options && (
        <div
          className={`absolute right-0 z-10 w-56 pb-2 mt-2 origin-top-right bg-[#171a3b] rounded-sm border border-[#494C6A]`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div role="none" className={`py-1 max-h-48 overflow-y-auto scrollbar-hide`}>
            {options.map((option) => (
              <button
                key={option?.id}
                onClick={() => selectOption(option)}
                className={`block w-full px-4 py-2 text-sm text-left ${
                  min && parseInt(option.label) <= min
                    ? 'text-gray-500 cursor-not-allowed'
                    : 'text-white'
                }`}
                role="menuitem"
                disabled={min && parseInt(option.label) <= min}
              >
                {option?.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownWithCount;
