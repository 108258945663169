/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import { createContext, useContext, useEffect, useState } from 'react';
import Service from '../services/services';
import { login } from '../services/auth/auth';

export const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(undefined);
  const [isLoging, setIsLogin] = useState(false);
  const [userCompany, setUserCompany] = useState(undefined);

  useEffect(() => {
    if (!session) {
      setIsLogin(false);
      setSession(Service.getSession());
    }
  }, []);

  const handleLogin = async (username, password) => {
    try {
      const s = await login(username, password);
      setSession(s);
      localStorage.setItem('session', JSON.stringify(s));
      return s;
    } catch (e) {
      console.log('Failed to login', e);
      setSession(undefined);
      throw 'Login failed';
    }
  };

  const handleLogout = (session) => setSession(undefined);

  const value = {
    session,
    setSession,
    login: handleLogin,
    logout: handleLogout,
    setIsLogin,
    isLoging,
    userCompany,
    setUserCompany,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
