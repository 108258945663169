/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { numberWithCommas } from '../helper/numberHelper';
import { useHistory } from 'react-router-dom';
import VisitorCartPopup from './state/visitorCartPopup';
import { getSubscriptionDurationLabel } from '../helper/stringHelper';
// import { QuestionMark } from '../assests/svg';
import { AdditionalServicesInfoModal } from './modals';

const Cart = (props) => {
  const { data, isButton } = props;

  const [isShowModal, setIsShowModal] = useState(false);
  const [isAdditionalServicesModal, setIsAdditionalServicesModal] = useState(false);

  const history = useHistory();
  const handleNavigate = () => {
    history.push('/checkout', { state: { data } });
  };

  const handleClosed = () => {
    setIsShowModal(false);
  };

  // const handleOpenAdditionalModal = useCallback(() => {
  //   setIsAdditionalServicesModal((prev) => !prev);
  // }, [isAdditionalServicesModal]);

  const handleCloseAdditionalModal = useCallback(() => {
    setIsAdditionalServicesModal(false);
  }, [isAdditionalServicesModal]);

  const isVisitor = data?.baseCost <= 0 && data?.name === 'VISITOR';

  return (
    <div>
      <div className="bg-[#171a3b] border border-[#494c6a] rounded p-6  h-full">
        <h2 className="text-center text-[#f9fffd] text-2xl font-bold mb-2">{data?.name}</h2>
        <div className="text-[#f9fffd] text-center text-6xl font-bold mb-1">
          <span className="text-xl">{data?.baseCost > 0 && '$'}</span>
          {isVisitor ? 'Free' : numberWithCommas(data?.baseCost)}
        </div>
        <p className="text-[#f9fffd] text-center text-sm mb-5">
          PER PREMISES / {getSubscriptionDurationLabel(data?.billingPeriod)} [EX. GST]
        </p>
        <ul>
          {!isVisitor && (
            <li className="flex text-[#ffffff] text-sm mb-2 items-center">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">
                {data?.features?.user?.limit} {data?.features?.user?.limit > 1 ? 'Users' : 'User'}
              </span>
            </li>
          )}
          {isVisitor && (
            <li className="flex items-center text-[#ffffff] text-sm mb-2">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">
                {' '}
                {data?.features?.user?.limit}{' '}
                {Number(data?.features?.user?.limit) > 1 ? 'Visitor account' : 'Visitor account'}
              </span>
            </li>
          )}
          {!isVisitor && (
            <li className="flex items-center text-[#ffffff] text-sm mb-2">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">
                {data?.features?.timesheet?.limit}{' '}
                {data?.features?.timesheet?.limit > 1 ? 'Timesheets' : 'Timesheet'}
              </span>
            </li>
          )}
          {!isVisitor && (
            <li className="flex items-center text-[#ffffff] text-sm mb-2">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">
                {data?.features?.timesheet?.limit}{' '}
                {data?.features?.timesheet?.limit > 1 ? "eCVD's" : "eCVD's"}
              </span>
            </li>
          )}
          {!isVisitor && (
            <li className="flex items-center text-[#ffffff] text-sm mb-2">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">
                {/* {data?.features?.maxGeofences?.value} Geofences */}
                Ultimate Geofences
              </span>
            </li>
          )}
          {!isVisitor && (
            <li className="flex items-center text-[#ffffff] text-sm mb-2">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">Mobile & Desktop Access</span>
            </li>
          )}
          {!isVisitor && (
            <li className="flex items-center text-[#ffffff] text-sm mb-2">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">Broadcast Capability</span>
            </li>
          )}
          {!isVisitor && (
            <li className="flex items-center text-[#ffffff] text-sm mb-2">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">Links</span>
            </li>
          )}
          {!isVisitor && (
            <li className="flex items-center text-[#ffffff] text-sm mb-2">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">Support & Logbook</span>
            </li>
          )}

          {isVisitor && (
            <li className="flex items-center text-[#ffffff] text-sm mb-2">
              <span className="flex-none mt-1 mr-2 text-[#7AEAA7]">→</span>
              <span className="grow">Free Mobile App</span>
            </li>
          )}
        </ul>
        <div className="grid gap-2">
          {!isButton && !isVisitor && (
            <div className="grid justify-center pt-1">
              <button
                className="mt-10 w-full mb-2 bg-[#7aeaa7] text-[#171a3b] py-3 px-20 rounded-full text-lg font-bold uppercase"
                onClick={handleNavigate}
              >
                Select
              </button>
            </div>
          )}
          {isVisitor && (
            <div className="grid justify-center pt-4">
              <button
                className="mt-10 w-full mb-2 bg-[#7aeaa7] text-[#171a3b] py-3 px-12 rounded-full text-lg font-bold uppercase"
                onClick={() => setIsShowModal(true)}
              >
                Upgrade later
              </button>
            </div>
          )}
          {/* {!isVisitor && (
            <p
              className="text-xs text-[#a3a5b8] flex gap-2 justify-center items-center cursor-pointer"
              onClick={handleOpenAdditionalModal}
            >
              ADDITIONAL SERVICES BILLED MONTHLY{' '}
              <span>
                <QuestionMark />
              </span>
            </p>
          )} */}
        </div>
      </div>
      {isShowModal && <VisitorCartPopup isOpen={isShowModal} onClick={handleClosed} />}
      {isAdditionalServicesModal && (
        <AdditionalServicesInfoModal
          isOpen={isAdditionalServicesModal}
          onClick={handleCloseAdditionalModal}
        />
      )}
    </div>
  );
};

export default Cart;
