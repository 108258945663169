export function getSubscriptionLabel(subscriptionType, duration) {
  const lowercaseSubscriptionType = subscriptionType?.toLowerCase();
  const lowercaseSubscriptionDuration = duration?.toLowerCase();

  if (lowercaseSubscriptionType.includes('standard annual')) {
    return 'Standard-Annually';
  } else if (lowercaseSubscriptionType.includes('premium annual')) {
    return 'Premium-Annually';
  } else if (
    lowercaseSubscriptionDuration === 'month' &&
    lowercaseSubscriptionType.includes('standard')
  ) {
    return 'Standard-Monthly';
  } else if (
    lowercaseSubscriptionDuration === 'month' &&
    lowercaseSubscriptionType.includes('premium')
  ) {
    return 'Premium-Monthly';
  } else {
    return 'Unknown Subscription';
  }
}

export function filterByIsLiveTrue(data) {
  if (data) {
    return data?.filter((item) => item?.type === 'WEB');
  }
}

export const getSubscriptionDurationLabel = (label = '') => {
  if (label === 'day') {
    return 'Daily';
  } else if (label === 'month') {
    return 'Monthly';
  } else if (label === 'year') {
    return 'Annually';
  } else {
    return '';
  }
};
