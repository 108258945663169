import React from 'react';

const Footer = () => {
  return (
    <>
      <footer className="py-4 bg-[#171a3b] w-full">
        <div className="bg-[#494C6A] h-0.5"></div>
        <div className="w-[80%] mx-auto py-[35px]">
          <div className="flex flex-col items-center justify-between max-w-screen-xl px-4 mx-auto md:flex-row">
            <div className="text-[#ffffff] text-center mb-4 md:mb-0 md:text-left">
              <p className="text-sm font-bold">BIOPLUS® Australia</p>
              <p className="text-sm font-light">For further information,</p>
              <p className="text-sm font-light">please contact our support team</p>
            </div>
            <div className="text-[#ffffff] text-center mb-4 md:mb-0 md:text-left">
              <p className="text-sm font-bold">
                Phone: <span className="font-light">+61 (0) 478 496 541</span>
              </p>
              <p className="text-sm font-bold">
                Email: <span className="font-light">sales@bioplus.live</span>
              </p>
            </div>
            <div className="text-[#ffffff] text-center md:text-left">
              <p className="text-sm font-bold">
                Hours:<span className="font-light"> Monday–Friday</span>
              </p>
              <p className="text-sm font-light">8:00am–5:30pm</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
