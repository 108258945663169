import React from 'react';

const Label = ({ fontSize, fontWeight, textColor, ...props }) => {
  let classes = '';

  if (fontSize) {
    classes += ` text-${fontSize}`;
  }

  if (fontWeight) {
    classes += ` font-${fontWeight}`;
  }

  if (textColor) {
    classes += ` text-${textColor}`;
  }

  return <label className={classes} {...props} />;
};

export default Label;
