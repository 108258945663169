import React from 'react';
import { Modal } from '../../components';

const AdditionalServicesInfoModal = (props) => {
  const { isOpen, onClick } = props;

  return (
    <Modal isOpen={isOpen}>
      <div className="border border-gray-200 rounded-lg shadow md:mx-auto sm:p-10 dark:bg-gray-800 dark:border-gray-700">
        <button
          className="absolute text-4xl text-gray-400 top-4 right-10 hover:text-gray-200"
          onClick={onClick}
        >
          &times;
        </button>
        <h2 className="mt-6 text-xl font-bold text-center">ADDITIONAL SERVICES</h2>
        <p className="mt-1 text-sm text-center text-gray-400">PER PREMISES / MONTHLY (INC. GST)</p>
        <div className="p-10 mt-10">
          <div className="flex justify-between py-4 gap-14">
            <span className="font-semibold">
              USERS <span className="text-sm font-normal text-gray-400">[PER FIVE USERS]</span>
            </span>
            <span>$16.50</span>
          </div>
          <div className="flex justify-between py-4 gap-14">
            <span className="font-semibold">
              TIMESHEETS{' '}
              <span className="text-sm font-normal text-gray-400">[PER FIVE TIMESHEETS]</span>
            </span>
            <span>$16.50</span>
          </div>
          <div className="flex justify-between py-4 gap-14">
            <span className="font-semibold">
              eCVD's <span className="text-sm font-normal text-gray-400">[PER FIVE eCVD's]</span>
            </span>
            <span>$18.50</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdditionalServicesInfoModal;
