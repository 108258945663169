import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Cart from './cart';
import { chekout } from '../services/subscription/subscription';
import { Loader } from '../assests';
import { useAuth } from '../hooks/AuthContext';
import { numberWithCommas } from '../helper/numberHelper';

const MakePayment = () => {
  const [isLoading, setIsLoadng] = useState(false);

  const { userCompany } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const data = location?.state;
  const planDetails = data?.state;

  const handleBack = () => history.push('/');

  const handleMakePayment = async () => {
    try {
      setIsLoadng(true);
      const payload = {
        mode: 'subscription',
        companyName: userCompany?.companyId?.name,
        companyId: userCompany?.companyId?.id,
        lineItems: [
          {
            price: planDetails?.priceId,
            quantity: Number(
              planDetails?.actualPremiseCount
                ? planDetails?.actualPremiseCount
                : planDetails?.features?.premise?.limit
            ),
          },
        ],
      };

      const response = await chekout(payload);
      if (response) {
        setIsLoadng(false);
        window.open(response, '_self');
      }
    } catch (error) {
      setIsLoadng(false);
      alert('Oops! We encountered an error. Please try again later.');
      console.error('Error making payment:', error);
    }
  };

  return (
    <div className="py-[30px]">
      {isLoading && (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <Loader />
        </div>
      )}
      <div className="w-[80%] mx-auto">
        <h1 className="text-[#7aeaa7] text-2xl font-semibold tracking-wide uppercase mb-[30px]">
          SUBSCRIPTION
        </h1>
        <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
          <div className="bg-[#171a3b] border border-[#494c6a] rounded px-6 h-fit text-center p-8 grid gap-3">
            <h1 className="text-2xl font-semibold tracking-wide text-white uppercase">
              {planDetails?.name}
            </h1>
            <div className="text-[#f9fffd] text-center text-6xl font-bold mb-1 ">
              <span className="text-2xl ">{planDetails?.baseCost > 0 && '$'}</span>
              {numberWithCommas(
                planDetails?.actualPremiseCount
                  ? planDetails?.baseCost * planDetails?.actualPremiseCount
                  : planDetails?.baseCost
              )}
            </div>
            <p className="text-[#f9fffd] text-center text-sm uppercase">
              Total cost per {planDetails?.billingPeriod}{' '}
              <span className="text-[10px]">[INC.GST]</span>
            </p>
            {/* {details of the plan} */}
            <div className="flex justify-between text-[#ffffff] py-[3px] uppercase">
              <span className="text-[#A3A5B8]">Users</span>
              <span>
                {planDetails?.premiseQuantity
                  ? planDetails?.features?.user?.limit * planDetails?.premiseQuantity
                  : planDetails?.features?.user?.limit}
              </span>
            </div>
            <div className="flex justify-between text-[#ffffff] py-[3px] uppercase">
              <span className="text-[#A3A5B8]">Timesheets</span>
              <span>
                {planDetails?.premiseQuantity
                  ? planDetails?.features?.timesheet?.limit * planDetails?.premiseQuantity
                  : planDetails?.features?.timesheet?.limit}
              </span>
            </div>
            <div className="flex justify-between text-[#ffffff] py-[3px] uppercase">
              <span className="text-[#A3A5B8]">eCVD's</span>
              <span>
                {planDetails?.premiseQuantity
                  ? planDetails?.features?.edec?.limit * planDetails?.premiseQuantity
                  : planDetails?.features?.edec?.limit}
              </span>
            </div>
            <div className="flex justify-between text-[#ffffff] py-[3px] uppercase">
              <span className="text-[#A3A5B8]">Geofences</span>
              <span>UNLIMITED</span>
            </div>
            <div className="flex justify-between text-[#ffffff] py-[3px] uppercase">
              <span className="text-[#A3A5B8]">Mobile & Desktop Access</span>
              <span>INCLUDED</span>
            </div>
            <div className="flex justify-between text-[#ffffff] py-[3px] uppercase">
              <span className="text-[#A3A5B8]">Broadcast Capability</span>
              <span>INCLUDED</span>
            </div>
            <div className="flex justify-between text-[#ffffff] py-[3px] uppercase">
              <span className="text-[#A3A5B8]">Links</span>
              <span>INCLUDED</span>
            </div>
            <div className="flex justify-between text-[#ffffff] py-[3px] uppercase">
              <span className="text-[#A3A5B8]">Links Support & Logbook</span>
              <span>INCLUDED</span>
            </div>
          </div>

          <div className="bg-[#171a3b] border border-[#494c6a] rounded px-6 h-fit relative">
            <p className="text-xl font-medium dark:text-white py-[30px] uppercase">
              Subscription Summary
            </p>

            <div className="py-4 border-t border-gray-300 dark:border-gray-700">
              <div className="flex justify-between text-[#ffffff] py-[20px]">
                <span>Subtotal (Sub total is excluding tax)</span>
                <span>
                  $
                  {planDetails?.actualPremiseCount
                    ? planDetails?.baseCost * planDetails?.actualPremiseCount
                    : planDetails?.baseCost}
                </span>
              </div>
              <div className="flex justify-between text-[#ffffff]">
                <span className="font-bold">Total</span>
                <span className="font-medium">
                  ${' '}
                  {planDetails?.actualPremiseCount
                    ? planDetails?.baseCost * planDetails?.actualPremiseCount
                    : planDetails?.baseCost}
                </span>
              </div>
              <div className="grid justify-center pt-4 pb-4">
                <button
                  className="mt-10 w-fit bg-[#7aeaa7] text-[#171a3b] py-3 px-14 rounded-full text-lg font-bold uppercase"
                  onClick={handleMakePayment}
                >
                  Make Payment
                </button>
              </div>
            </div>
            <p
              className="text-[#A3A5B8] underline underline-offset-2 cursor-pointer text-sm absolute bottom-[-50px] left-1/2 transform -translate-x-1/2"
              onClick={handleBack}
            >
              BACK TO PRODUCT
            </p>
          </div>
        </div>
        <div className="mt-16 pb-4 text-xs text-[#767789] grid grid-cols-2">
          <div>
            <p>
              PREMISE is defined as the Primary Property with an adjoining property which is using
              the same Property Identification Code (PIC) in accordance with the regulations
              controlled by the State Government of Victoria.
            </p>
            <p>UNLIMITED Geofences per PREMISE as defined above.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakePayment;
