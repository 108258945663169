import React from 'react';
import { Modal } from '../../components';

const PremiseAdditionalInfoModal = (props) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen}>
      <div className="border border-gray-200 rounded-lg shadow md:mx-auto sm:p-10 dark:bg-gray-800 dark:border-gray-700 max-w-[500px]">
        <button
          className="absolute text-4xl text-gray-400 top-4 right-10 hover:text-gray-200"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="mt-6 mb-10 text-xl font-bold text-center text-white">PREMISES</h2>
        <p className="text-[#f9fffd] text-center text-sm uppercase leading-normal">
          PREMISE is defined as the Primary Property with an adjoining property which is using the
          same Property Identification Code (PIC) in accordance with the regulations controlled by
          the State Government of Victoria.
        </p>
        <p className="text-[#f9fffd] text-center text-sm pt-6">UNLIMITED Geofences per PREMISE.</p>
      </div>
    </Modal>
  );
};

export default PremiseAdditionalInfoModal;
