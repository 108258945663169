import React from 'react';
import './BillingToggle.css';

const BillingToggle = ({ toggleBilling, isMonthly }) => {
  return (
    <div className="flex items-center space-x-4">
      <span className={`text-white ${!isMonthly && 'opacity-50'}`}>BILLED MONTHLY</span>
      <div
        className={`toggle-track ${isMonthly ? 'bg-gray-400' : 'bg-green-400'}`}
        onClick={toggleBilling}
      >
        <div className={`toggle-thumb ${isMonthly ? 'translate-x-0' : 'translate-x-6'}`}></div>
      </div>
      <span className={`text-white ${isMonthly && 'opacity-50'}`}>
        BILLED ANNUALLY <span className="text-green-400">{!isMonthly && '(SAVE 12%)'}</span>
      </span>
    </div>
  );
};

export default BillingToggle;
