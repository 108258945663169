import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import { BioPlusLogo } from '../../assests';
import { getUserCompanies } from '../../services/company/company';
import { ReactComponent as UserSvg } from '../../assests/userDefault.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import HearderDropdown from '../../components/dropDownField/hearderDropDown';

const Header = ({ isLogin }) => {
  const [userCompanies, setUserCompanies] = useState();
  const [selectedCompany, setSelectedCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowUserDropDown, setIsShowUserDropDown] = useState(false);

  const userDropdown = useRef(null);
  const history = useHistory();
  const { logout, setIsLogin, setUserCompany } = useAuth();
  const handleLogout = () => {
    setIsLogin(false);
    logout();
    localStorage.removeItem('session');
    history.push('/');
  };

  const fetchUserCompanies = async () => {
    try {
      setIsLoading(true);
      const data = await getUserCompanies();

      if (data) {
        setIsLoading(false);
        setSelectedCompany(data[0]?.companyId?.name);

        setUserCompany(data[0]);
        setUserCompanies(data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleOnOptionSelect = (value) => {
    if (value) {
      setSelectedCompany(value?.companyId?.name);
      setUserCompany(value);
    }
  };

  useEffect(() => {
    if (!isLogin) {
      fetchUserCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UserProfilePanel = () => {
    return (
      <div className="absolute bottom-[-139px] right-0 w-full max-w-xs bg-[#171a3b] shadow-lg border border-[#7AEAA7] z-10">
        <div className="flex items-center justify-between p-4 ">
          <div className="flex gap-4">
            <span className="text-white">👥</span>
            <div className="text-lg font-semibold text-white">
              {userCompanies && userCompanies?.length > 0
                ? `${userCompanies[0]?.userId?.firstName} ${userCompanies[0]?.userId?.lastName}`
                : 'Not Provided'}
            </div>
          </div>
        </div>

        <div
          className="flex items-center justify-between p-4 bg-[#171a3b] border-t border-[#7AEAA7]  cursor-pointer hover:bg-gray-700"
          onClick={handleLogout}
        >
          <div className="flex gap-4">
            <span className="text-white">↩</span>
            <span className="flex items-center text-white">Sign Out</span>
          </div>
        </div>
      </div>
    );
  };

  useOutsideClick({
    isOpen: isShowUserDropDown,
    node: userDropdown,
    onOutsideClick: () => setIsShowUserDropDown(false),
  });

  return (
    <nav className="bg-[#171a3b] w-full sticky top-0 z-50" ref={userDropdown}>
      <div className="flex items-center justify-between h-20 max-w-screen-xl px-4 mx-auto">
        <a href="/" className="flex items-center">
          <img src={BioPlusLogo} alt="Logo" className="cursor-pointer w-[160px] h-[44px]" />
        </a>
        {!isLogin && (
          <div className="relative flex items-center gap-6">
            {!isLoading && userCompanies ? (
              <HearderDropdown
                options={userCompanies}
                onOptionSelect={handleOnOptionSelect}
                selectedOption={selectedCompany}
              />
            ) : (
              <p className="text-white">Loading...</p>
            )}
            <button
              className="text-white p-1 rounded-full bg-[#ffffff20]"
              onClick={() => setIsShowUserDropDown(!isShowUserDropDown)}
            >
              <UserSvg />
            </button>
            {isShowUserDropDown && <>{UserProfilePanel()}</>}
          </div>
        )}
      </div>
      <div className="bg-[#7aeaa7] h-0.5"></div>
    </nav>
  );
};

export default Header;
