export const numberWithCommas = (x = 0, decimals = 2) => {
  x = Number(parseFloat(x?.toString())?.toFixed(decimals));
  return x?.toString()?.split('.')[0].length > 3
    ? x
        .toString()
        .substring(0, x.toString().split('.')[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
        ',' +
        x.toString().substring(x.toString().split('.')[0].length - 3)
    : x.toString();
};

export function formatAndReorderArray(arr) {
  if (!Array.isArray(arr)) {
    return [];
  }

  const order = [
    'VISITOR',
    'STANDARD-ANNUALLY',
    'STANDARD-MONTHLY',
    'PREMIUM-MONTHLY',
    'PREMIUM-ANNUALLY',
  ];

  const orderedArray = order.map((name) => {
    const formattedName = name.replace(/ /g, '').toLowerCase();
    return arr.find((obj) => obj.name.replace(/ /g, '').toLowerCase() === formattedName);
  });

  return orderedArray;
}

export function getMultipliedArray(array, multiplier) {
  return array
    ?.map((item) => ({
      label: (parseInt(item.value) * multiplier).toString(),
      value: (parseInt(item.value) * multiplier).toString(),
      actualCount: item.label,
    }))
    .filter((item) => parseInt(item.value) <= 10);
}
