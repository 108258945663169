import React from 'react';
import { Modal } from '../../components';

const RegistrationSuccessPopup = (props) => {
  const { isOpen, onClick } = props;
  return (
    <Modal isOpen={isOpen}>
      <div className="p-6 bg-white border border-gray-200 rounded-lg shadow md:mx-auto sm:p-10 dark:bg-gray-800 dark:border-gray-700">
        <svg viewBox="0 0 24 24" className="w-16 h-16 mx-auto my-6 text-green-600">
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
        <div className="text-center">
          <h3 className="text-2xl font-medium text-white">Congratulations!</h3>
          <p className="my-2 text-base font-normal text-white">
            Your registration has been successfully processed!
          </p>
          <p className="text-base font-normal text-white">Have a great day!</p>
          <div className="grid justify-center pt-4 pb-10">
            <button
              className="mt-10  w-fit mb-2 bg-[#7aeaa7] text-[#171a3b] py-3 rounded-full text-lg font-bold uppercase px-24"
              onClick={onClick}
            >
              Sign in
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RegistrationSuccessPopup;
