import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InputField, Label } from '../../components';
import { useForm } from 'react-hook-form';
import { formDefaultState } from './type';
import {
  login,
  registration,
  resendVerificationCode,
  verification,
} from '../../services/auth/auth';
import { useAuth } from '../../hooks/AuthContext';
import RegistrationSuccessPopup from './registrationSuccessPopup';
import { getCountry, getstateList } from '../../constant';
import { Dropdown } from '../../components/dropDownField';
import Header from '../app/header';

const loginSteps = {
  registration: 'REGISTRATION',
  verify: 'VERIFY',
  loging: 'LOGIN',
};

const RegistrationForm = () => {
  const [formStep, setFormStep] = useState(loginSteps.registration);
  const { setIsLogin } = useAuth();
  const [isModalShow, setIsModalShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState('Select your state');
  const [selectedCountry, setSelectedCountry] = useState('Select your country');

  const methods = useForm({
    defaultValues: formDefaultState,
    mode: 'all',
  });
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = methods;

  const handleSave = async (value) => {
    const payload = {
      email: value.email,
      password: value.password,
      firstName: value.firstName,
      lastName: value.lastName,
      phone: value.phone,
      countryCode: `+${value.countryCode}`,
      addressState: value.state,
      addressStreet: value.address,
      addressTown: value.town,
      zipCode: value.zip_code,
      country: value.country,
      companyName: value.company_name,
    };
    try {
      await registration(payload);
      setFormStep(loginSteps.verify);
    } catch (error) {
      const msg = error?.response?.data?.message;
      alert(`Registration failed, ${msg}`);
    }
  };

  const handleVerification = async (value) => {
    try {
      await verification(value.email, value.verificationCode);
      setIsModalShow(true);
    } catch (error) {
      alert('Verification failed, try again later.');
    }
  };

  const handleResendVerificationCode = async () => {
    const email = getValues('email');
    if (!email) {
      alert('Please provide a valid email ID');
      return;
    }
    try {
      await resendVerificationCode(email);
      alert('Please check your email to find the new OTP.');
    } catch (error) {
      alert('Something went wrong, try again later.');
    }
  };

  const handlePopup = () => {
    setIsModalShow(false);
    setFormStep(loginSteps.loging);
  };

  const handleLogin = async (value) => {
    try {
      const response = await login(value.email, value.password);
      if (response) {
        setIsLogin(true);
        history.push('/shopping-cart');
      }
    } catch (error) {
      if (error?.response?.data?.status === 'Authorization Error') {
        alert('Email is not verified. Please verify your email, then try to log in.');
        setFormStep(loginSteps.verify);
      } else {
        alert('Login failed, invalid credentials provided.');
      }
    }
  };

  const handleOnOptionSelect = (value) => {
    if (value) {
      setValue('state', value?.label);
      setSelectedValue(value?.label);
    }
  };
  const handleOnOptionSelectForCountry = (value) => {
    if (value) {
      setValue('country', value?.label);
      setSelectedCountry(value?.label);
    }
  };

  const stateListBasedOnEnv = getstateList(process.env.REACT_APP_ENV);
  const country = getCountry(process.env.REACT_APP_ENV);

  return (
    <>
      <Header isLogin={true} />
      <div className="bg-[#171a3b] text-[#f9fffd] font-space text-base">
        {formStep === loginSteps.registration ? (
          <div className="w-[70%] mx-auto">
            <>
              <div className="flex justify-between gap-8 py-[27px]">
                <div>
                  <p className="text-[#7aeaa7] text-2xl font-semibold uppercase">
                    Registration Form
                  </p>
                </div>
                <div>
                  <p
                    className="text-xl font-semibold uppercase cursor-pointer"
                    onClick={() => setFormStep(loginSteps.loging)}
                  >
                    Already User?{' '}
                    <span className="text-[#7aeaa7] underline underline-offset-2">Sign in</span>
                  </p>
                </div>
              </div>

              <div>
                <div className="relative pb-[8px] ">
                  <InputField
                    label="Company Name"
                    // isRequired={true}
                    {...register('company_name', {
                      required: 'This field is required',
                    })}
                    placeholder="Company Name"
                  />
                  {errors.company_name && (
                    <Label className="text-red-500 text-xs font-sans absolute bottom-[4px]">
                      {errors.company_name.message}
                    </Label>
                  )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8 pb-[10px]">
                  <div className="relative">
                    <InputField
                      label="First Name"
                      isRequired={true}
                      {...register(`firstName`, {
                        required: 'This field is required',
                      })}
                      placeholder="First Name"
                    />
                    {errors.firstName && (
                      <Label className="text-red-500 text-xs font-sans absolute bottom-[-3px]">
                        {errors.firstName.message}
                      </Label>
                    )}
                  </div>
                  <div className="relative">
                    <InputField
                      label="Last Name"
                      isRequired={true}
                      {...register(`lastName`, {
                        required: 'This field is required',
                      })}
                      placeholder="Last Name"
                    />
                    {errors.lastName && (
                      <Label className="text-red-500 text-xs font-sans absolute bottom-[-3px]">
                        {errors.lastName.message}
                      </Label>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8 pb-[10px]">
                  <div className="relative">
                    <InputField
                      label="Primary Email"
                      isRequired={true}
                      {...register(`email`, { required: 'This field is required' })}
                      type="email"
                      autocomplete="off"
                      placeholder="Primary Email"
                    />
                    {errors.email && (
                      <Label className="text-red-500 text-xs font-sans absolute bottom-[-4px]">
                        {errors.email.message}
                      </Label>
                    )}
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-[.5fr,2fr] md:gap-4">
                    <div className="relative rounded-md shadow-sm">
                      <InputField
                        label="Code"
                        type="number"
                        isRequired={true}
                        {...register(`countryCode`, { required: 'Required' })}
                        placeholder="+61"
                      />{' '}
                      {errors.countryCode && (
                        <Label className="text-red-500 text-xs font-sans absolute bottom-[-4px]">
                          {errors.countryCode.message}
                        </Label>
                      )}
                    </div>
                    <div className="relative">
                      <InputField
                        label="Mobile Number"
                        isRequired={true}
                        {...register(`phone`, {
                          required: 'This field is required',
                          pattern: {
                            value: /^[\d\s]+$/,
                            message: 'Only numeric characters are allowed',
                          },
                        })}
                        placeholder="Mobile Number"
                      />
                      {errors.phone && (
                        <Label className="text-red-500 text-xs font-sans absolute bottom-[-4px]">
                          {errors.phone.message}
                        </Label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="relative pb-[10px]">
                  <InputField
                    label="Password"
                    type="password"
                    autocomplete="off"
                    {...register(`password`, { required: 'This field is required' })}
                    placeholder="Password"
                  />
                  {errors.password && (
                    <Label className="text-red-500 text-xs font-sans absolute bottom-[4px]">
                      {errors.password.message}
                    </Label>
                  )}
                </div>
                <div className="relative pb-[10px]">
                  <InputField
                    label="Address"
                    isRequired={true}
                    {...register(`address`, {
                      required: 'This field is required',
                    })}
                    placeholder="Address"
                  />
                  {errors.address && (
                    <Label className="text-red-500 text-xs font-sans absolute bottom-[4px]">
                      {errors.address.message}
                    </Label>
                  )}
                </div>{' '}
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8 pb-[10px]">
                  <div className="relative flex items-center">
                    {/* <InputField
                      label="Country"
                      isRequired={true}
                      {...register(`country`, { required: 'This field is required' })}
                    />
                    {errors.country && (
                      <Label className="text-red-500 text-xs font-sans absolute bottom-[-4px]">
                        {errors.country.message}
                      </Label>
                    )} */}
                    <Dropdown
                      options={country}
                      onOptionSelect={handleOnOptionSelectForCountry}
                      selectedOption={selectedCountry}
                    />
                  </div>
                  <div className="relative">
                    <InputField
                      label="Town"
                      isRequired={true}
                      {...register(`town`, { required: 'This field is required' })}
                      placeholder="Town"
                    />
                    {errors.town && (
                      <Label className="text-red-500 text-xs font-sans absolute bottom-[-4px]">
                        {errors.town.message}
                      </Label>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                  <div className="relative flex items-center">
                    {/* <InputField
                      label="State"
                      isRequired={true}
                      {...register(`state`, { required: 'This field is required' })}
                    />
                    {errors.state && (
                      <Label className="text-red-500 text-xs font-sans absolute bottom-[-4px]">
                        {errors.state.message}
                      </Label>
                    )} */}
                    <Dropdown
                      options={stateListBasedOnEnv}
                      onOptionSelect={handleOnOptionSelect}
                      selectedOption={selectedValue}
                    />
                  </div>
                  <div className="relative">
                    <InputField
                      label="Zip Code"
                      isRequired={true}
                      {...register(`zip_code`, { required: 'This field is required' })}
                      placeholder="Zip Code"
                    />
                    {errors.zip_code && (
                      <Label className="text-red-500 text-xs font-sans absolute bottom-[-4px]">
                        {errors.zip_code.message}
                      </Label>
                    )}
                  </div>
                </div>
              </div>
            </>
            <div className="grid justify-center pt-4 pb-10">
              <button
                className="mt-10  w-fit mb-2 bg-[#7aeaa7] text-[#171a3b] py-3 rounded-full text-lg font-bold uppercase px-24"
                onClick={handleSubmit(handleSave)}
              >
                Submit
              </button>
            </div>
          </div>
        ) : formStep === loginSteps.verify ? (
          <div className="w-[70%] mx-auto">
            <div className="flex justify-between gap-8 py-[27px]">
              <div>
                <p className="text-[#7aeaa7] text-2xl font-semibold uppercase">Verification</p>
              </div>
              <div>
                <p
                  className="text-xl font-semibold uppercase cursor-pointer"
                  onClick={handleResendVerificationCode}
                >
                  <span className="text-[#7aeaa7] underline underline-offset-2 cursor-pointer">
                    Resend OTP
                  </span>
                </p>
              </div>
            </div>
            <>
              <div className="relative pb-[10px]">
                <InputField
                  label="Primary Email"
                  isRequired={true}
                  {...register(`email`, { required: 'This field is required' })}
                  type="email"
                  autocomplete="off"
                  placeholder="Primary Email"
                />
                {errors.email && (
                  <Label className="text-red-500 text-xs font-sans absolute bottom-[-5px] pb-[10px]">
                    {errors.email.message}
                  </Label>
                )}
              </div>
              <div>
                <div className="relative pb-[6px] ">
                  <InputField
                    label="Enter Your OTP"
                    {...register('verificationCode')}
                    placeholder="Enter Your OTP"
                  />
                </div>
              </div>
            </>

            <div className="grid justify-center pt-4 pb-10">
              <button
                className="mt-10  w-fit mb-2 bg-[#7aeaa7] text-[#171a3b] py-3 rounded-full text-lg font-bold uppercase px-24"
                onClick={handleSubmit(handleVerification)}
              >
                Verify
              </button>
            </div>
          </div>
        ) : (
          <div className="w-[70%] mx-auto">
            <div className="flex justify-between gap-8 py-[27px]">
              <div>
                <p className="text-[#7aeaa7] text-2xl font-semibold uppercase">Login Form</p>
              </div>
              <div>
                <p
                  className="text-xl font-semibold uppercase cursor-pointer"
                  onClick={() => setFormStep(loginSteps.registration)}
                >
                  New User?{' '}
                  <span className="text-[#7aeaa7] underline underline-offset-2">Registration</span>
                </p>
              </div>
            </div>

            <>
              <div className="mt-[20px]">
                <div className="relative pb-[6px] ">
                  <InputField
                    label="Primary Email"
                    {...register('email')}
                    placeholder="Primary Email"
                  />
                </div>
              </div>
              <div>
                <div className="relative pb-[6px] ">
                  <InputField
                    label="Enter Your Password"
                    type="password"
                    {...register('password')}
                    placeholder="Your Password"
                  />
                </div>
              </div>
            </>
            <div className="grid justify-center pt-4 pb-10">
              <button
                className="mt-10  w-fit mb-2 bg-[#7aeaa7] text-[#171a3b] py-3 rounded-full text-lg font-bold uppercase px-24"
                onClick={handleSubmit(handleLogin)}
              >
                Sign in
              </button>
            </div>
          </div>
        )}
        {isModalShow && <RegistrationSuccessPopup isOpen={isModalShow} onClick={handlePopup} />}
      </div>
    </>
  );
};

export default RegistrationForm;
