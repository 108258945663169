/* eslint-disable no-throw-literal */
import services from '../services';

export const getPlans = async () => {
  const response = await services.get(`/subscription-plans`);

  if (response.status === 200) {
    const data = response.data;

    return data;
  }

  throw `Unable to get plans. ${response.statusText}`;
};

export const chekout = async (payload) => {
  const response = await services.post(`/subscriptions`, payload);

  if (response.status === 200) {
    const data = response.data;

    return data;
  }

  throw `Unable to process the subscription for the plan. ${response.statusText}`;
};
