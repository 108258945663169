import React from 'react';
import { DownloadButton, Modal } from '../../components';

const VisitorCartPopup = (props) => {
  const { isOpen, onClick } = props;

  const handleToAppleStore = () => {
    window.open('https://apps.apple.com/au/app/bioplus/id6444330345', '_blank');
  };
  const handleToGoogleStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.itrakassets.bioplus&pli=1',
      '_blank'
    );
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="p-6 bg-white border border-gray-200 rounded-lg shadow md:mx-auto sm:p-10 dark:bg-gray-800 dark:border-gray-700">
        <svg viewBox="0 0 24 24" className="w-16 h-16 mx-auto my-6 text-green-600">
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
        <div className="text-center">
          <h3 className="text-2xl font-medium text-gray-500 dark:text-white">Congratulations!</h3>
          <p className="my-2 text-xl font-normal text-[#ffffff]">
            You have successfully registered on the BioPlus Global platform.
          </p>
          <p className="my-2 text-xl font-normal text-[#ffffff]">
            To continue, download the free app from the app store onto your mobile device.
          </p>
          <div className="flex justify-center gap-4 pt-3">
            <DownloadButton storeType="appStore" click={handleToAppleStore} />
            <DownloadButton storeType="googlePlay" click={handleToGoogleStore} />
          </div>
          <div className="py-10 text-center">
            <button
              className="mt-10 w-full mb-2 bg-[#7aeaa7] text-[#171a3b] py-3 px-6 rounded-full text-lg font-bold uppercase"
              onClick={onClick}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VisitorCartPopup;
