import React from 'react';

import { Switch, Route } from 'react-router-dom';
import {
  RegistrationForm,
  PaymentSuccessful,
  ShoppingCart,
  PaymentFailed,
  MakePayment,
} from '../pages';
import { useAuth } from '../hooks/AuthContext';
import Header from '../pages/app/header';
import Footer from '../pages/app/footer';

import Checkout from '../pages/checkout';

// const NotFound = () => <h1>Page Not Found</h1>;

const notFoundRoute = {
  path: '*',
  component: ShoppingCart,
  exact: false,
};

export const routes = [
  {
    path: '/shopping-cart',
    component: ShoppingCart,
    exact: true,
  },
  {
    path: '/checkout',
    component: Checkout,
    exact: true,
  },
  {
    path: '/makePayment',
    component: MakePayment,
    exact: true,
  },
  {
    path: '/payment-success',
    component: PaymentSuccessful,
    exact: true,
  },
  {
    path: '/payment-fail',
    component: PaymentFailed,
    exact: true,
  },
].concat(notFoundRoute);

const Routes = () => {
  const { session, isLoging } = useAuth();

  if (!!session?.token || isLoging) {
    return (
      <>
        <Header />
        <div className="bg-[#171a3b]">
          <Switch>
            {routes.map((route, i) => {
              return (
                <Route key={i} path={route.path} exact={route.exact} component={route.component} />
              );
            })}
          </Switch>
        </div>
        <Footer />
      </>
    );
  } else {
    return <Route path={'*'} exact={true} component={RegistrationForm} />;
  }
};

export default Routes;
