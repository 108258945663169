import React, { useEffect, useState } from 'react';
import Cart from './cart';
import { getPlans } from '../services/subscription/subscription';
import { Loader } from '../assests';
import { BillingToggle } from '../components';

const ShoppingCart = () => {
  const [subscriptionPlan, setSubscriptionPlan] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isMonthly, setIsMonthly] = useState(true);

  const toggleBilling = () => {
    setIsMonthly(!isMonthly);
  };

  const fetchSubscription = async () => {
    try {
      setIsLoading(true);
      const data = await getPlans();
      if (data) {
        setSubscriptionPlan(data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  if (isLoading) {
    return (
      <div className="z-50 flex items-center justify-center h-screen bg-[#171a3b] bg-opacity-50 ">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#171a3b] text-[#f9fffd] font-space text-base">
        <div className="w-[80%] mx-auto">
          <section className="px-6">
            <div className="flex justify-between">
              <h1 className="text-[#7aeaa7] text-2xl font-semibold tracking-wide uppercase py-[30px]">
                SELECT SUBSCRIPTION
              </h1>
              <BillingToggle toggleBilling={toggleBilling} isMonthly={isMonthly} />
            </div>
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
              {subscriptionPlan && isMonthly
                ? subscriptionPlan
                    ?.filter(
                      (plan) =>
                        (plan?.billingPeriod === 'month' || plan?.billingPeriod === 'day') &&
                        plan?.type !== 'addOn'
                    )
                    ?.map((plan) => <Cart data={plan} key={plan?.id} />)
                : subscriptionPlan
                    ?.filter((plan) => !isMonthly && plan.billingPeriod === 'year')
                    .map((plan) => <Cart data={plan} key={plan?.id} />)}
            </div>
            <div className="mt-16 pb-4 text-xs text-[#767789] grid grid-cols-2">
              <div>
                <p>
                  PREMISE is defined as the Primary Property with an adjoining property which is
                  using the same Property Identification Code (PIC) in accordance with the
                  regulations controlled by the State Government of Victoria.
                </p>
                <p>UNLIMITED Geofences per PREMISE as defined above.</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ShoppingCart;
