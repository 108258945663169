import React from 'react';
import Label from '../label';

const InputField = React.forwardRef((props, ref) => {
  const { label, placeholder, isRequired, ...prop } = props;
  return (
    <div className="grid grid-cols-1 gap-[6px] mb-4">
      {label ? (
        <Label className="block mb-2">
          {label} {isRequired && <span className="text-white-500">*</span>}
        </Label>
      ) : (
        <Label className="invisible block font-sans text-sm font-semibold text-white">.</Label>
      )}
      <input
        className="w-full p-2 text-white bg-transparent border-b border-gray-600 focus:outline-none focus:border-[#7aeaa7]"
        placeholder={placeholder}
        {...prop}
        ref={ref}
        autocomplete="off"
      />
    </div>
  );
});

export default InputField;
