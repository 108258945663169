import React from 'react';

const Modal = (props) => {
  const { isOpen = false, children } = props;

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'block' : 'hidden'
      } w-full h-full bg-[#212445] bg-opacity-50 border border-[#494C6A]`}
    >
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className={`relative mx-auto ${isOpen ? 'block' : 'hidden'}`}>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
