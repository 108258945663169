/* eslint-disable no-throw-literal */
import client from './axios';

let session = undefined;

const validatedSession = () => {
  const now = new Date().getTime();

  if (session.expiry < now) {
    session = undefined;
    localStorage.removeItem('session');
    return undefined;
  }

  return session;
};

export const getSession = () => {
  if (!!session) {
    return validatedSession();
  } else {
    let json = localStorage.getItem('session');

    if (!json) return undefined;
    session = JSON.parse(json);

    return validatedSession();
  }
};

export const setSession = (newSession) => {
  if (!!newSession) {
    session = newSession;
    localStorage.setItem('session', JSON.stringify(newSession));
  } else {
    localStorage.removeItem('session');
  }
};

const get = async (uri) => {
  if (!getSession()) throw 'Not logged in';

  console.log('GET CALLED');

  const response = await client.get(uri, {
    headers: {
      Authorization: `Bearer ${session.token}`,
    },
  });

  return response;
};

const post = async (uri, payload) => {
  if (!getSession()) throw 'Not logged in';

  const response = await client.post(uri, payload, {
    headers: {
      Authorization: `Bearer ${session.token}`,
    },
  });

  return response;
};

const postPublic = async (uri, payload) => {
  const response = await client.post(uri, payload);

  return response;
};

const put = async (uri, payload) => {
  if (!getSession()) throw 'Not logged in';

  const response = await client.put(uri, payload, {
    headers: {
      Authorization: `Bearer ${session.token}`,
    },
  });

  return response;
};

const remove = async (uri) => {
  if (!getSession()) throw 'Not logged in';

  const response = await client.delete(uri, {
    headers: {
      Authorization: `Bearer ${session.token}`,
    },
  });

  return response;
};

const emptyPageResult = () => ({
  items: [],
  page: 0,
  pageSize: 10,
  length: 0,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSession,
  setSession,
  get,
  post,
  postPublic,
  put,
  emptyPageResult,
  remove,
};
