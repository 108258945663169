export const formDefaultState = {
  company_name: '',
  password: '',
  email: '',
  phone: '',
  address: '',
  country: '',
  town: '',
  state: '',
  zip_code: '',
  firstName: '',
  lastName: '',
  countryCode: '',
  verificationCode: '',
};
