import React from 'react';
import { useHistory } from 'react-router-dom';

const PaymentFailed = () => {
  const history = useHistory();
  return (
    <div className="m-[80px]">
      <div className="flex items-center justify-center pb-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="150.63"
          height="100.971"
          viewBox="0 0 44.63 38.971"
        >
          <g id="alert-triangle" transform="translate(0.001 -1.397)">
            <path
              id="Path_92"
              data-name="Path 92"
              d="M18.915,4.81l-16.83,28.1a3.974,3.974,0,0,0,3.4,5.961h33.66a3.974,3.974,0,0,0,3.4-5.961L25.71,4.81a3.974,3.974,0,0,0-6.8,0Z"
              transform="translate(0 0)"
              fill="none"
              stroke="#eb0000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <line
              id="Line_166"
              data-name="Line 166"
              y2="7.948"
              transform="translate(22.313 15.024)"
              fill="none"
              stroke="#eb0000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <line
              id="Line_167"
              data-name="Line 167"
              x2="0.02"
              transform="translate(22.313 30.92)"
              fill="none"
              stroke="#eb0000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
          </g>
        </svg>
      </div>

      <div className="text-center">
        <h3 className="text-2xl font-medium text-gray-500 dark:text-white cls">Payment Failed!</h3>
        <p className="pt-2 text-base font-normal text-white cls">
          Your payment request is failed, please try again later !
        </p>

        <div className="grid justify-center py-10 text-center">
          <button
            className="mt-10 px-14 mb-2 cls bg-[#7aeaa7] text-[#171a3b] py-3  rounded-full text-lg font-bold uppercase"
            onClick={() => {
              history.push('/');
            }}
          >
            GO BACK
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
