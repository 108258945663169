import axios from 'axios';

const getBaseURL = (env) => {
  switch (env) {
    case 'development':
      return process.env.REACT_APP_DEV_URL;
    case 'stage':
      return process.env.REACT_APP_STAGE_URL;
    case 'production':
      return process.env.REACT_APP_PROD_URL;
    case 'development_au':
      return process.env.REACT_APP_DEV_AU_URL;
    case 'stage_au':
      return process.env.REACT_APP_STAGE_AU_URL;
    case 'production_au':
      return process.env.REACT_APP_PROD_AU_URL;
    default:
      // return process.env.REACT_APP_STAGE_AU_URL;
      return process.env.REACT_APP_STAGE_URL;
  }
};

const client = axios.create({
  baseURL: getBaseURL(process.env.REACT_APP_ENV),
});

client.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default client;
